// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Styles placed at the top of the Shadow Root. Keep it minimal.
 */

/**
 * Removes top and bottom margins from paragraphs.
 * Allows usage of paragraphs in tight containers.
 */
p {
  margin: 1em 0;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/styles/root.css"],"names":[],"mappings":"AAAA;;EAEE;;AAEF;;;EAGE;AACF;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["/**\n * Styles placed at the top of the Shadow Root. Keep it minimal.\n */\n\n/**\n * Removes top and bottom margins from paragraphs.\n * Allows usage of paragraphs in tight containers.\n */\np {\n  margin: 1em 0;\n}\n\np:first-child {\n  margin-top: 0;\n}\n\np:last-child {\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
